import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45416723"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination-container d-f justify-e" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_table, {
      columns: _ctx.columns,
      pagination: false,
      dataSource: _ctx.data,
      rowKey: "id",
      rowClassName: (record, index) => (index % 2 === 1 ? 'table-striped' : null),
      style: {"margin-bottom":"24px"},
      scroll: {y: 380}
    }, {
      invoiceDateTitle: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.title) + " " + _toDisplayString(_ctx.$t('retailValidationSipo.invoiceDate')), 1)
      ]),
      invoiceBillingDateTitle: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.title) + " ACQ " + _toDisplayString(_ctx.$t('retailValidationSipo.invoiceDate')), 1)
      ]),
      licenseDateTitle: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.title) + " " + _toDisplayString(_ctx.$t('retailValidationSipo.registrationDate')), 1)
      ]),
      licenseBillingDateTitle: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.title) + " ACQ " + _toDisplayString(_ctx.$t('retailValidationSipo.registrationDate')), 1)
      ]),
      specialUsageTitle: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.title) + " " + _toDisplayString(_ctx.$t('retailValidationSipo.vehicleUsage')), 1)
      ]),
      customerInvoiceDate: _withCtx(({ text }) => [
        _createTextVNode(_toDisplayString(text ? _ctx.moment(text).format('YYYY/MM/DD') : ''), 1)
      ]),
      acquisitionInvoiceDate: _withCtx(({ text }) => [
        _createTextVNode(_toDisplayString(text ? _ctx.moment(text).format('YYYY/MM/DD') : ''), 1)
      ]),
      registrationDate: _withCtx(({ text }) => [
        _createTextVNode(_toDisplayString(text ? _ctx.moment(text).format('YYYY/MM/DD') : ''), 1)
      ]),
      acquisitionRegistrationDate: _withCtx(({ text }) => [
        _createTextVNode(_toDisplayString(text ? _ctx.moment(text).format('YYYY/MM/DD') : ''), 1)
      ]),
      vehicleCount: _withCtx(({ text }) => [
        _createTextVNode(_toDisplayString(text === 1 ? '销售' : ( text === -1 ? '退车' : '')), 1)
      ]),
      _: 1
    }, 8, ["columns", "dataSource", "rowClassName"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_pagination, {
        total: _ctx.total,
        "show-size-changer": "",
        "show-total": (total) => _ctx.locale === 'en' ? `Total ${total} items` : `共 ${total} 条`,
        onChange: _ctx.pageChange,
        onShowSizeChange: _ctx.sizeChange,
        pageSize: _ctx.size,
        pageSizeOptions: ['10','20','30','40','50']
      }, {
        buildOptionText: _withCtx((props) => [
          _createElementVNode("span", null, _toDisplayString(props.value) + _toDisplayString(_ctx.locale === 'en' ? ' / page' : '条/页'), 1)
        ]),
        _: 1
      }, 8, ["total", "show-total", "onChange", "onShowSizeChange", "pageSize"])
    ])
  ], 64))
}