
import { defineComponent } from 'vue'
import BasicInfo from './BasicInfo.vue'
import SipoInfo from './SipoInfo.vue'
import RetailHistoryInfo from './RetailHistoryInfo.vue'
import OperRecord from './OperRecord.vue'
export default defineComponent({
  components: {
    BasicInfo,
    SipoInfo,
    RetailHistoryInfo,
    OperRecord
  },
  props: {
    isDetailVisible: {
        type: Boolean,
        required: true,
        default: false
    }
  },
  setup (props, context) {
    const modal = (props as any).isDetailVisible
    const close = () => {
      context.emit('close-detail-visible', { isVisible: false })
    }
    const changeTab = (event: string) => {      
      const dom = document.getElementsByClassName('ant-table-body')[(~~event) - 1]
      if (dom) {
         dom.scrollTo(0, 0)
      }
    }
    return {
      modal,
      close,
      changeTab
    }
  }
})
