
import { defineComponent, ref } from "vue";
import { getBasicDetail } from "@/API/rv/rvSipo";
import { EnumSipoApprovalStatus, EnumSipoCheckStatus } from "@/views/RetailValidation/Sipo/types";
export default defineComponent({
  props: {
  },
  setup() {
    const data = ref({})
    const initData = () => {
      const params = {
        id: sessionStorage.getItem("sipoRecordId")
      };
      getBasicDetail({ params }).then((res): void => {
        // res.price = '￥' + res.price.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,');
        data.value = res;
      })
    };
    initData()
    return {
      data,
      EnumSipoApprovalStatus,
      EnumSipoCheckStatus
    };
  },
});
