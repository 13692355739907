
import { defineComponent, ref, toRefs, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import useSipoOverview from '@/hooks/rv/userSipoOverview';
import useDealerOverview from '@/hooks/rv/useDealerOverview';
import useSiccoOverview from '@/hooks/rv/useSiccoOverview';
import downloadFile from '@/utils/rv/downloadFile';
import FailReason from '@/views/RetailValidation/Sipo/FailReason/index.vue';
import RetailValidationDetail from '@/views/RetailValidation/Sipo/SipoDetail/index.vue';
import { SipoTableData, EnumSipoApprovalStatus, EnumSipoCheckStatus } from '@/views/RetailValidation/Sipo/types';
import cleanEmptyEleArray from '@/utils/rv/cleanEmptyElement';
import Preview from '@/views/RetailValidation/component/Preview.vue';
import { message } from 'ant-design-vue';
import { getSpecialUsageItem } from '@/API/rv/rvDealer';
import BrandSelector from '@/views/RetailValidation/component/BrandSelector.vue';
const usageArray = ['最终用户用车', '经销商用车', '其他', '全部'];
const specialUsageArray = ref<any>([]);
const entityArray = ['FBAC', 'BBAC', 'MBCL', '全部'];
const brandArray = ['MB', 'AMG', 'EQ', 'VAN', 'DENZA', 'Maybach', '全部'];
const tableHeight = window.innerHeight - 310;
const tableWidth = window.innerWidth;
export default defineComponent({
  components: {
    RetailValidationDetail,
    FailReason,
    Preview,
    BrandSelector
  },
  setup() {
    const { t, locale } = useI18n();
    // 详情弹窗是否显示
    const isDetailVisible = ref<boolean>(false);
    const entityData = ref<string>('');
    const { pictureModal, licenseEvent, invoiceEvent, relationProofEvent, closePictureModal } = useDealerOverview();
    const {
      queryParams,
      resetEvent,
      isOpenPanle,
      isOpen,
      tableData,
      total,
      failData,
      activeKey,
      mulSearchEvent,
      showFailFlag,
      pageChange,
      sizeChange,
      viewReasonEvent,
      handleClick,
      handleActiveKey,
      showCollapsed,
      columns,
      batchQuery,
      approvalStatusArray,
      rebateFlagArray,
      dealerNameRes,
      dealerHandleSearch,
      dateChangeEvent,
      selectData
    } = useSipoOverview();
    const { dealerNameEnArray, swtArray, coficoArray, dealerNameCnArray, handleSearch } = useSiccoOverview();
    // 批量查询文本框
    const input = ref();
    const textContentNo = ref<string>('');
    const textNoArray = ref<string[]>([]);
    const batchPlaceholder = ref(['', '', '', '', '', '']);
    // 设置批量查询modal 的 textNoArray
    const setBatchQueryTextNoArray = () => {
      if (batchQuery.batchQueryTitle === t('retailValidationSipo.vinBatchEnquiry')) {
        textNoArray.value =
          cleanEmptyEleArray(queryParams.vinNos)[0] === '' ? [] : cleanEmptyEleArray(queryParams.vinNos);
      } else {
        textNoArray.value =
          cleanEmptyEleArray(queryParams.commissionNos)[0] === '' ? [] : cleanEmptyEleArray(queryParams.commissionNos);
      }
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ['', '', '', '', '', ''];
      }
    };
    // 批量查询按钮事件
    const batchQueryEvent = (arg: string) => {
      if (arg === 'vinNo') {
        batchQuery.batchQueryModal = true;
        batchQuery.batchQueryTitle = t('retailValidationSipo.vinBatchEnquiry');
        batchQuery.batchQueryListTitle = t('retailValidationSipo.vinList');
      } else {
        batchQuery.batchQueryModal = true;
        batchQuery.batchQueryTitle = t('retailValidationSipo.commissionNoBatchEnquiry');
        batchQuery.batchQueryListTitle = t('retailValidationSipo.commissionNoList');
      }
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      const dom = document.getElementsByClassName('list-content')[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error('请从Excel中粘贴到文本框');
        textContentNo.value = '';
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value
          .replace(/\r|\n|\s/g, ',')
          .split(',')
          .filter((item) => item)
      );
      const dom = document.getElementsByClassName('list-content')[0];
      textContentNo.value = '';
      if (batchQuery.batchQueryTitle === t('retailValidationSipo.vinBatchEnquiry')) {
        queryParams.vinNos = [];
        queryParams.vinNos = queryParams.vinNos.concat(textNoArray.value);
      } else {
        queryParams.commissionNos = [];
        queryParams.commissionNos = queryParams.commissionNos.concat(textNoArray.value);
      }
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      queryParams.vinNo = queryParams.vinNos[0];
      queryParams.commissionNo = queryParams.commissionNos[0];
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      if (batchQuery.batchQueryTitle === t('retailValidationSipo.vinBatchEnquiry')) {
        queryParams.vinNos = [''];
      } else {
        queryParams.commissionNos = [''];
      }
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      input.value.focus();
    };
    const exportResult = () => {
      const params = {
        url: `/rvapi/basic/approval/sipo/resultExport`,
        method: 'post',
        params: queryParams,
        fileName: `${+new Date()}.xlsx`
      };
      downloadFile(params);
    };
    // 查看详情
    const detailEvent = (record: SipoTableData) => {
      sessionStorage.setItem('sipoRecordId', String(record.id));
      sessionStorage.setItem('sipoRecordEntity', record.entity);
      sessionStorage.setItem('sipoRecordVinNo', record.vinNo);
      sessionStorage.setItem('sipoRecordDealerCode', record.dealerCode);
      isDetailVisible.value = true;
    };
    // 关闭弹窗
    const closeDetail = (param: { isVisible: boolean }) => {
      isDetailVisible.value = param.isVisible;
    };
    // 关闭失败原因弹窗
    const closeFailReason = () => {
      showFailFlag.value = false;
    };
    //获取特殊车辆
    const getSpecialUsageArray = () => {
      getSpecialUsageItem().then((res) => {
        res.forEach((item: any) => {
          specialUsageArray.value.push(item.specialUsageCn);
        });
      });
    };
    getSpecialUsageArray();
    return {
      isOpen,
      isOpenPanle,
      showFailFlag,
      columns,
      activeKey,
      handleClick,
      handleActiveKey,
      showCollapsed,
      ...toRefs(batchQuery),
      ...toRefs(queryParams),
      ...toRefs(pictureModal),
      pictureModal,
      tableData,
      mulSearchEvent,
      usageArray,
      specialUsageArray,
      entityArray,
      approvalStatusArray,
      rebateFlagArray,
      total,
      pageChange,
      sizeChange,
      queryParams,
      viewReasonEvent,
      exportResult,
      detailEvent,
      isDetailVisible,
      closeDetail,
      closeFailReason,
      failData,
      entityData,
      resetEvent,
      licenseEvent,
      invoiceEvent,
      relationProofEvent,
      tableHeight,
      tableWidth,
      batchQueryEvent,
      textContentNo,
      textChangEvent,
      textNoArray,
      batchPlaceholder,
      EnumSipoCheckStatus,
      EnumSipoApprovalStatus,
      clearBatchQuery,
      closeBatchQueryModal,
      dealerNameRes,
      dealerHandleSearch,
      dealerNameEnArray,
      swtArray,
      coficoArray,
      dealerNameCnArray,
      handleSearch,
      input,
      dateChangeEvent,
      ...toRefs(selectData),
      closePictureModal,
      brandArray,
      locale
    };
  }
});
