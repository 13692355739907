import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-488cea60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "operRecord" }
const _hoisted_2 = { class: "operPagination" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_table, {
        rowKey: 
        (record, index) => {
          return record.createdAt + index.toString();
        }
      ,
        columns: _ctx.columns,
        pagination: false,
        dataSource: _ctx.data,
        rowClassName: 
        (record, index) => (index % 2 === 1 ? 'table-striped' : null)
      ,
        scroll: { y: 380 }
      }, {
        userName: _withCtx(({ text }) => [
          _createElementVNode("span", null, _toDisplayString(text), 1)
        ]),
        actionName: _withCtx(({ text }) => [
          _createElementVNode("span", null, _toDisplayString(text), 1)
        ]),
        createdAt: _withCtx(({ text }) => [
          _createElementVNode("span", null, _toDisplayString(text), 1)
        ]),
        _: 1
      }, 8, ["rowKey", "columns", "dataSource", "rowClassName"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_pagination, {
        total: _ctx.total,
        "show-size-changer": "",
        "show-total": (total) => _ctx.locale === 'en' ? `Total ${total} items` : `共 ${total} 条`,
        onChange: _ctx.pageChange,
        onShowSizeChange: _ctx.sizeChange,
        pageSize: _ctx.size,
        "onUpdate:pageSize": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.size) = $event)),
        pageSizeOptions: ['10', '20', '30', '40', '50']
      }, {
        buildOptionText: _withCtx((props) => [
          _createElementVNode("span", null, _toDisplayString(props.value) + _toDisplayString(_ctx.locale === 'en' ? ' / page' : '条/页'), 1)
        ]),
        _: 1
      }, 8, ["total", "show-total", "onChange", "onShowSizeChange", "pageSize"])
    ])
  ], 64))
}