
import { defineComponent, ref } from "vue";
import { getDealerInfo } from "@/API/rv/rvSipo";
import moment from 'moment';
export default defineComponent({
  props: {
  },
  setup() {
    const data = ref({})
    const initData = () => {
      const params = {
        id: Number(sessionStorage.getItem("sipoRecordId"))
      };
      getDealerInfo({ params }).then((res: any): void => {
        data.value = res;
      })
    };
    initData()
    return {
      moment,
      data
    };
  },
});
