
import { defineComponent, PropType, computed } from "vue";
import { s3Download } from "@/utils/s3Download";
interface PictureType {
  pictureModalFlag: boolean;
  pictureModalTitle: string;
  pictureModalImg: string;
  pdf: string;
  url: string;
  dealerCode: string;
}
export default defineComponent({
  emits: ["close-picture-modal"],
  props: {
    pictureModal: {
      type: Object as PropType<PictureType>,
      required: true,
      default: () => ({}),
    },
    footer: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    const close = () => {
      context.emit("close-picture-modal");
    };
    const downloadFile = async () => {
      const vinNo = props.pictureModal.pictureModalTitle.substr(3);
      const fileName = props.pictureModal.pictureModalTitle;
      await s3Download({
        url: `${props.pictureModal.url}?vinNo=${vinNo}&fileName=${fileName}&dealerCode=${props.pictureModal.dealerCode}`,
        method: "get",
      });
    };
    const isPdf = computed(() => {
      let r = false;
      const pdf = props.pictureModal.pdf;
      if (pdf && pdf.length > 0) {
        r = true;
      }
      return r;
    });
    const centered = computed(() => {
      return !isPdf.value;
    });
    const width = computed(() => {
      return isPdf.value ? "100%" : 500;
    });
    const wrapClassName = computed(() => {
      return isPdf.value ? "" : "full-modal";
    });

    return {
      close,
      isPdf,
      centered,
      width,
      wrapClassName,
      downloadFile,
      // pdf,
    };
  },
});
