import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin":"-22px 0 0 24px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseCircleOutlined = _resolveComponent("CloseCircleOutlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.failFlag,
    footer: null,
    width: 600,
    centered: "",
    onCancel: _ctx.close
  }, {
    title: _withCtx(() => [
      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('retailValidationSipo.failureReasonDetails')), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        columns: _ctx.failColumns,
        pagination: false,
        dataSource: _ctx.failData,
        rowKey: "id",
        bordered: ""
      }, {
        comments: _withCtx(({ text }) => [
          _createElementVNode("div", null, [
            _createVNode(_component_CloseCircleOutlined, { style: { fontSize: '16px', color: '#ff7875' } })
          ]),
          _createElementVNode("div", _hoisted_1, _toDisplayString(text), 1)
        ]),
        replyAt: _withCtx(({ text }) => [
          _createTextVNode(_toDisplayString(_ctx.moment(text).format('YYYY/MM/DD hh:mm:ss')), 1)
        ]),
        _: 1
      }, 8, ["columns", "dataSource"])
    ]),
    _: 1
  }, 8, ["visible", "onCancel"]))
}