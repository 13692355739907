
import { defineComponent, PropType } from 'vue'
import { CloseCircleOutlined } from "@ant-design/icons-vue"
import { SipoFailReasonData } from "@/views/RetailValidation/Sipo/types";
import moment from "moment";
import { useI18n } from 'vue-i18n';
export default defineComponent({
  components: { CloseCircleOutlined },
  props: {
    showFailFlag: {
      default: false,
      type: Boolean
    },
    failData: {
      default: () => [],
      type: Array as PropType<SipoFailReasonData[]>
    }
  },
  setup(props, context) {
    const { t } = useI18n()

    const failColumns = [
      { title: t('retailValidationSipo.failureReason'), dataIndex: "comments", width: 350, slots: { customRender: "comments" }},
      {
        title: t('retailValidationSipo.feedbackTime'),
        dataIndex: "replyAt",
        width: 200,
        slots: { customRender: "replyAt" }
      }
    ]

    const failFlag = (props as any).showFailFlag
    const close = () => {
      context.emit('close-fail-reason')
    }
    return {
      moment,
      failColumns,
      failFlag,
      close
    }
  }
})
