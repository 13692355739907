
import { defineComponent, ref} from 'vue'
import { useI18n } from 'vue-i18n';
import {SipoRetailHistoryData} from '@/views/RetailValidation/Sipo/types';
import {getRetailHistory} from "@/API/rv/rvSipo"
import moment from 'moment'
export default defineComponent({
    setup () {
      const { t, locale } = useI18n()

      const columns = [ 
        { title: t('retailValidationSipo.vin'), dataIndex: "vin", width: 150 },
        {
          title: t('retailValidationSipo.retailType'),
          dataIndex: locale.value === 'en' ? "oasisUsageHistoryEn" : "oasisUsageHistoryCn",
          width: 120,
        },
        {
          dataIndex: "customerInvoiceDate",
          width: 130,
          slots: { title: "invoiceDateTitle", customRender: "customerInvoiceDate" }
        },
        {
          dataIndex: "acquisitionInvoiceDate",
          width: 160,
          slots: { title: "invoiceBillingDateTitle", customRender: "acquisitionInvoiceDate" }
        },
        {
          dataIndex: "registrationDate",
          width: 130,
          slots: { title: "licenseDateTitle", customRender: "registrationDate" }
        },
        {
          dataIndex: "acquisitionRegistrationDate",
          width: 160,
          slots: { title: "licenseBillingDateTitle", customRender: "invoiceDate" }
        },
        {
          dataIndex: "specialUsage",
          width: 130,
          slots: { title: "specialUsageTitle" }
        },
        {
          title: t('retailValidationSipo.retailStatus'),
          dataIndex: "vehicleCount",
          width: 100,
          slots: { customRender: "vehicleCount" }
        }
      ]

      const data = ref<SipoRetailHistoryData[]>([])
      const title = ref<string>('')
      const current = ref<number>(1)
      const size = ref<number>(20)
      const total = ref<number>(0)
      const initData = () => {
        const params = {
          vin: sessionStorage.getItem('sipoRecordVinNo'),
          page: current.value - 1,
          size: size.value,
        }
        getRetailHistory({params}).then((res: any) => {
          data.value = res.content
          total.value = res.totalElements
        })
      }
      title.value = (sessionStorage.getItem('sipoRecordEntity') === 'MBCL'|| sessionStorage.getItem('sipoRecordEntity') === 'BBAC') ? 'CESAR' : sessionStorage.getItem('sipoRecordEntity') === 'FBAC' ? 'SWT-DFE' : 'DOS'
      initData()
      const pageChange = (page: number, pageSize: number) => {
        current.value = page > 0 ? page : 1;
        size.value = pageSize
        initData()
      }
      const sizeChange = (page: number, pageSize: number) => {
        current.value = page > 0 ? page : 1;
        size.value = pageSize
        initData()
      }
      const usageText = (oasisUsage: string) => {
        if (oasisUsage === 'End Customer' || oasisUsage === '最终用户') {
          return '最终用户用车'
        }
        if (oasisUsage === 'Company Car' || oasisUsage === '经销商用车') {
          return '经销商用车'
        }
        return ''
      }
      return {
          columns,
          data,
          title,
          pageChange,
          sizeChange,
          total,
          size,
          usageText,
          moment,
          locale
      }
    }
})
