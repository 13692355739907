import { ref, reactive } from "vue";
import { SipoTableData, SipoFailReasonData, SipoStatusData }
  from "@/views/RetailValidation/Sipo/types";
import * as API from "@/API/rv/rvSipo";
import { getRegionsbyBuList } from "@/API/rv/rvSicco";
import setColumnWidth from "@/utils/rv/setColumnWidth"
import cleanEmptyEleArray from "@/utils/rv/cleanEmptyElement"
import { resetParam, resetPatrial } from '@/utils/rv/configParam'
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const useSipoOverview = () => {
  const { t } = useI18n();

  const { state } = useStore()
  const buIdsArray: number[] = state.user.buList.map((item: any) => {
    return item.id
  });
  // 打开筛选条件
  const isOpen = ref<boolean>(false)
  // 筛选面板
  const activeKey = ref<string>("")
  // 校验列表
  const tableData = ref<SipoTableData[]>([])
  const total = ref<number>(0)
  // 失败原因列表
  const failData = ref<SipoFailReasonData[]>([])
  // 是否显示失败原因面板
  const showFailFlag = ref<boolean>(false)
  // 状态下拉框
  const approvalStatusArray = ref<SipoStatusData[]>([])
  // 激励适用性
  const rebateFlagArray = ref<SipoStatusData[]>([])
  // 批量查询
  const batchQuery = reactive({
    batchQueryTitle: '',
    batchQueryModal: false,
    batchQueryListTitle: ''
  })
  // 多维查询下拉框备选项
  const selectData = reactive({
    areasArray: [],
    powerTrainArray: [],
    modelYearArray: [],
    typeClassArray: []
  })
  const columns = reactive([
    { title: t('retailValidationSipo.vin'), dataIndex: "vinNo", width: 168, fixed: 'left' },
    { title: t('retailValidationSipo.model'), dataIndex: "model", key: "model", width: 60 },
    { title: t('retailValidationSipo.brand'), dataIndex: "brand", width: 80 },
    { title: t('retailValidationSipo.dealerNameCN'), dataIndex: "dealerNameCn", key: "dealerNameCn", width: 150 },
    { title: t('retailValidationSipo.dealerCodeCofico'), dataIndex: "dealerCofficoCode", width: 150 },
    { title: t('retailValidationSipo.dealerCodeSWT'), dataIndex: "dealerSwtCode", width: 150, key: "dealerSwtCode" },
    { title: t('retailValidationSipo.region'), dataIndex: "region", width: 90, key: "region" },
    { title: t('retailValidationSipo.customerName'), dataIndex: "customerName", width: 100 },
    { title: t('retailValidationSipo.retailType'), dataIndex: "usage", width: 110, key: "usage" },
    { title: t('retailValidationSipo.retailDate'), dataIndex: "retailDate", width: 90, key: "retailDate" },
    { title: t('retailValidationSipo.specialUsage'), dataIndex: "specialUsage", width: 116, key: "specialUsage", },
    { title: t('retailValidationSipo.submissionStatus'), dataIndex: "approlStatus", width: 90, key: "approlStatus", slots: { customRender: "approlStatus" } },
    {
      title: t('retailValidationSipo.eligibilityStatus'), dataIndex: "checkStatus", width: 104,
      key: "checkStatus", slots: { customRender: "checkStatus" }
    },
    { title:  t('retailValidationSipo.failureReason'), key: "fail", fixed: 'right', width: 90, slots: { customRender: "fail" } },
    { title: t('retailValidationSipo.file'), key: "file", fixed: 'right', width: 120, slots: { customRender: "file" } },
    {
      title: t('retailValidationSipo.operation'), key: "operation", fixed: 'right', width: 90, slots: { customRender: "action" }
    },
  ])

  // 收起按钮显示位置 false 在折叠面板的header
  const showCollapsed = ref<boolean>(false)
  // 打开筛选条件面板
  const isOpenPanle = ref<boolean>(false)
  // 发票/行驶证 查看弹窗
  const pictureModal = reactive({
    pictureModalFlag: false,
    pictureModalTitle: "",
    pictureModalImg: "",
  });
  // 筛选条件
  const queryParams = reactive({
    vinNo: "",
    model: "",
    retailStartDate: "",
    retailEndDate: "",
    usage: "",
    commissionNo: "",
    typeClass: "",
    modelYear: "",
    entity: "",
    specialUsage: "",
    powerTrain: "",
    invoiceStartDate: "",
    invoiceEndDate: "",
    licenseStartDate: "",
    licenseEndDate: "",
    approlStatus: "",
    brand: "",
    checkStatus: "",
    region: "",
    commissionNos: [''],
    dealerCofficoCode: '',
    dealerSwtCode: '',
    vinNos: [''],
    dealerNameCn: '',
    dealerNameEn: '',
    current: 1,
    size: 20,
  });
  // 模糊查询经销商名称
  const dealerNameRes = ref([]);
  const resetEvent = () => {
    resetPatrial(queryParams)
    queryParams.commissionNos = [''],
      queryParams.vinNos = [''],
      queryParams.current = 1,
      queryParams.size = 20
  };
  // 模糊查询经销商方法
  const getDealerName = (val: string) => {
    const params = {
      dealerName: val,
      flag: 'C'
    }
    API.searchDealerName({ params }).then((res: any) => {
      dealerNameRes.value = res;
    });
  }
  const dealerHandleSearch = (val: string) => {
    queryParams.dealerNameCn = val;
    if (val.trim()) {
      getDealerName(val);
    } else {
      dealerNameRes.value = [];
    }
  };
  const getTableData = () => {
    queryParams.vinNo = queryParams.vinNo.trim()
    queryParams.commissionNo = queryParams.commissionNo.trim()
    queryParams.model = queryParams.model.trim()
    queryParams.dealerNameCn = queryParams.dealerNameCn.trim()
    queryParams.dealerNameEn = queryParams.dealerNameEn.trim()
    queryParams.vinNos = cleanEmptyEleArray(queryParams.vinNos)
    queryParams.commissionNos = cleanEmptyEleArray(queryParams.commissionNos)
    API.getRVTable(queryParams).then((res) => {
      tableData.value = res.content;
      total.value = res.totalElements;
      queryParams.size = res.size
      const customerColumn = ['model', 'dealerNameCn', 'customerName', 'specialUsage', 'brand', 'retailDate']
      tableData.value.length && Object.assign(columns, setColumnWidth(columns, tableData.value, customerColumn))
    });
  }
  API.getApprovalStatus().then(res => {
    approvalStatusArray.value = res
    approvalStatusArray.value.push(
      { code: "全部", msg: "全部" }, // '全部' 根据story添加一个状态
    )
  })
  API.getCheckStatus().then(res => {
    rebateFlagArray.value = res
    // '全部' 根据story添加一个状态
    rebateFlagArray.value.push({ code: "全部", msg: "全部" })
  })
  API.getSelectData().then((res: any) => {
    const newModelYearArray: any = [];
    const newpowerTrainArray: any = [];
    const newtypeClassArray: any = [];
    const newareasArray: any = []
    res.modelYear.forEach((item: any) => {
      if (item.msg !== "" && item.code !== "") {
        newModelYearArray.push(item);
        selectData.modelYearArray = newModelYearArray;
      }
    })
    res.powerTrain.forEach((item: any) => {
      if (item.msg !== "" && item.code !== "") {
        newpowerTrainArray.push(item);
        selectData.powerTrainArray = newpowerTrainArray;
      }
    })
    res.typeClass.forEach((item: any) => {
      if (item.msg !== "" && item.code !== "") {
        newtypeClassArray.push(item);
        selectData.typeClassArray = newtypeClassArray;
      }
    })
    Array.prototype.concat(res.salesRegion).forEach((item: any) => {
      if (item.msg !== "" && item.code !== "") {
        newareasArray.push(item);
        selectData.areasArray = newareasArray;
      }
    })
  })

  getRegionsbyBuList({ params: { buIds: buIdsArray } }).then((res: any) => {
    const regionArray: any = []
    /*if(res.code=== '0'){
      res.data.forEach((item: any)=>{
        if(item.regionDto?.length>0){
          item.regionDto.forEach((itemCld: any)=>{
            const obj = Object.assign({})
            obj.key = item?.id+'_'+itemCld?.id
            obj.code = item?.id+'_'+itemCld?.id
            obj.msg = item?.nameEn+'_'+itemCld?.regionEn
            regionArray.push(obj)
          })
        }
        else{
          const obj = Object.assign({})
          obj.key = item.id+'_'
          obj.code = item.id+'_'
          obj.msg = item.nameEn+'_'
          regionArray.push(obj)
        }
      })
      selectData.areasArray = regionArray;
      //console.log('selectData 区域=====',selectData.areasArray)
    }
    else{
      message.error(res.message)
    }*/
    if (res.code === '0') {
      const keys = new Set<string>()
      res.data.forEach((item: any) => {
        item.regionDto.forEach((itemCld: any) => {
          const key = `${item?.id}_${itemCld?.id}`
          if (!keys.has(key)) {
            regionArray.push({
              key,
              code: key,
              msg: `${item?.nameEn}_${itemCld?.regionEn}_${itemCld?.subRegionEn}`
            })
            keys.add(key)
          }
        })
      })
      selectData.areasArray = regionArray;
    }
    else {
      message.error(res.message)
    }
  })
  const mulSearchEvent = () => {
    resetParam(queryParams)
    queryParams.current = 1
    isOpenPanle.value = false
    isOpen.value = false
    activeKey.value = ""
    getTableData()
  }

  const licenseEvent = (vinNo: string, dealerCode: string) => {
    const params = { vinNo, dealerCode };
    API.getlicenseView({ params }).then(res => {
      pictureModal.pictureModalFlag = true;
      pictureModal.pictureModalTitle = "RE_" + vinNo
      pictureModal.pictureModalImg = res;
    });
  };
  const invoiceEvent = (vinNo: string, dealerCode: string) => {
    const params = { vinNo, dealerCode };
    API.getInvioceView({ params }).then(res => {
      pictureModal.pictureModalFlag = true;
      pictureModal.pictureModalImg = res;
      pictureModal.pictureModalTitle = "IN_" + vinNo;
    });
  }
  const dateChangeEvent = (date: string[], arg: string) => {
    switch (arg) {
      case 'retail':
        queryParams.retailStartDate = date[0]
        queryParams.retailEndDate = date[1]
        break
      case 'invoice':
        queryParams.invoiceStartDate = date[0]
        queryParams.invoiceEndDate = date[1]
        break
      case 'license':
        queryParams.licenseStartDate = date[0]
        queryParams.licenseEndDate = date[1]
        break
    }
  }
  const pageChange = (page: number, pageSize: number) => {
    queryParams.current = page > 0 ? page : 1
    queryParams.size = pageSize;
    getTableData()
  };
  const sizeChange = (page: number, pageSize: number) => {
    queryParams.current = page > 0 ? page : 1
    queryParams.size = pageSize;
    getTableData()
  };
  const viewReasonEvent = (vinNo: string, dealerCode: string) => {
    const params = { vinNo, dealerCode };
    API.getFailReasonData({ params }).then((res) => {
      failData.value = res;
      showFailFlag.value = true;
    });
  };
  const handleClick = (event: MouseEvent) => {
    isOpenPanle.value = false;
    activeKey.value = ""
    event.stopPropagation();
  };
  const handleActiveKey = () => {
    if (activeKey.value.indexOf("4") !== -1) {
      showCollapsed.value = true;
    } else {
      showCollapsed.value = false;
    }
  };
  return {
    getTableData,
    resetEvent,
    mulSearchEvent,
    pageChange,
    sizeChange,
    licenseEvent,
    invoiceEvent,
    viewReasonEvent,
    handleClick,
    handleActiveKey,
    dealerHandleSearch,
    getDealerName,
    dateChangeEvent,
    columns,
    failData,
    queryParams,
    isOpenPanle,
    isOpen,
    tableData,
    total,
    pictureModal,
    activeKey,
    showFailFlag,
    showCollapsed,
    batchQuery,
    approvalStatusArray,
    rebateFlagArray,
    dealerNameRes,
    selectData
  }
}
export default useSipoOverview
