import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!

  return (_openBlock(), _createBlock(_component_a_descriptions, {
    column: 1,
    bordered: "",
    class: "infoDescription"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_descriptions_item, {
        label: `${_ctx.$t('retailValidationSipo.vin')}：`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.vinNo), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_a_descriptions_item, {
        label: `${_ctx.$t('retailValidationSipo.model')}：`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.model), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_a_descriptions_item, {
        label: `${_ctx.$t('retailValidationSipo.invoiceDate')}：`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.invoiceDate), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_a_descriptions_item, {
        label: `${_ctx.$t('retailValidationSipo.registrationDate')}：`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.licenseDate), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_a_descriptions_item, {
        label: `${_ctx.$t('retailValidationSipo.customerName')}：`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.customerName), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_a_descriptions_item, {
        label: `${_ctx.$t('retailValidationSipo.retailType')}：`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.usage), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_a_descriptions_item, {
        label: `${_ctx.$t('retailValidationSipo.submissionStatus')}：`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.EnumSipoApprovalStatus[_ctx.data.approlStatus]), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_a_descriptions_item, {
        label: `${_ctx.$t('retailValidationSipo.eligibilityStatus')}：`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.EnumSipoCheckStatus[_ctx.data.checkStatus]), 1)
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }))
}