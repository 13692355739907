/*
 * @Author: your name
 * @Date: 2021-06-28 10:11:14
 * @LastEditTime: 2021-10-09 13:46:13
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \oasis-fontend\src\views\RetailValidation\Sipo\types.ts
 */
export interface SipoTableData {
  approlStatus: string;
  customerName: string;
  id: number;
  model: string;
  retailDate: string;
  specialUsage: string;
  usabilityRebateFlag: string;
  usage: string;
  vinNo: string;
  invoicePath: string;
  licensePath: string;
  entity: string;
  failFlag: string;
  modelYear: string;
  brand: string;
  dealerCode: string;
}

export interface SipoFailReasonData {
  id?: number;
  comments: string;
  replyAt: string;
}

export interface SipoRetailHistoryData {
  id: number;
  invoiceBillingDate: string;
  invoiceDate: string;
  licenseBillingDate: string;
  licenseDate: string;
  saleFlag: string;
  specialUsage: string;
  usage: string;
  vinNo: string;
}

export interface SipoStatusData {
  msg: string;
  code: string;
}

export const EnumSipoApprovalStatus = {
  '0000': '待提交',
  '0001': '延迟待提交',
  '1111': '已过期',
  '2222': '已提交',
  '3333': '已过期'
};

export const EnumSipoCheckStatus = {
  '0000': '无',
  '1111': '系统检查中',
  '1112': '待审核',
  '1113': '修改待审核',
  '2222': '有效',
  '3333': '无效',
  '4444': '退车'
};

interface SelectArray {
  code: string;
  msg: string;
}
export interface SelectData {
  salesRegion: SelectArray[];
  powerTrain: SelectArray[];
  modelYear: SelectArray[];
  typeClass: SelectArray[];
}
