import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_info = _resolveComponent("basic-info")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_sipo_info = _resolveComponent("sipo-info")!
  const _component_retail_history_info = _resolveComponent("retail-history-info")!
  const _component_oper_record = _resolveComponent("oper-record")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.$t('retailValidationSipo.retailDataDetails'),
    class: "retailModal",
    width: "84%",
    center: "",
    style: {"height":"600px","padding-bottom":"0"},
    visible: _ctx.modal,
    footer: null,
    headerStyle: {fontWeight: 'bold'},
    onCancel: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, {
        defaultActiveKey: "1",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTab($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            tab: _ctx.$t('retailValidationSipo.basicInformtion'),
            key: "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_basic_info)
            ]),
            _: 1
          }, 8, ["tab"]),
          _createVNode(_component_a_tab_pane, {
            tab: _ctx.$t('retailValidationSipo.dealerInformtion'),
            key: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_sipo_info)
            ]),
            _: 1
          }, 8, ["tab"]),
          _createVNode(_component_a_tab_pane, {
            tab: _ctx.$t('retailValidationSipo.vehicleRetailHistory'),
            key: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_retail_history_info)
            ]),
            _: 1
          }, 8, ["tab"]),
          _createVNode(_component_a_tab_pane, {
            tab: _ctx.$t('retailValidationSipo.operationRecord'),
            key: "4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_oper_record)
            ]),
            _: 1
          }, 8, ["tab"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "visible", "onCancel"]))
}